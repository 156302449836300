import React, { useEffect, useState } from 'react';
import *  as  Realm from "realm-web";
import Marquee from "react-fast-marquee";

const App = () => {
  const app = new Realm.App({ id: "application-0-onino" });

  // Set state variables
  const [user, setUser] = useState();
  const [events, setEvents] = useState([]);


  // This useEffect hook will run only once when the page is loaded

  useEffect(() => {
    const login = async () => {
      // Authenticate anonymously
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user);

      // Connect to the database
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("test").collection("newsmodels");

    if(events.length == 0){
        const allProducts = await collection.find({});
        setEvents(allProducts)
    }
    
    var updatedEvents = [];

    for await (const change of collection.watch()) {
      // console.log(change.fullDocument)
        if (change.operationType === 'insert' || change.operationType === 'update' || change.operationType === 'replace') {
            updatedEvents.push(change.fullDocument);
        }
        setEvents(updatedEvents);
        // Eski listeyi temizleme
        
    }
    
    }
    login();
  }, [events]);



  // Return the JSX that will generate HTML for the page
  return (
    <>

      {!!user &&
      <Marquee className='marquee-guncel-haberler'>

                {events.map((key, i) => (
                  <h5 style={{margin:"15px"}} key={i}>({new Date(key.date).getHours()+":"+new Date(key.date).getMinutes()})-{key.title}</h5>
                ))}
        </Marquee>
 
      }
    </>
  );

};

export default App;

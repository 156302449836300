import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { getExchangeRates } from "./CurrencyService.js";
import { Swiper, SwiperSlide } from "swiper/react";
import MarqueGuncelHaberler from "./marque-guncel-haberler.jsx";
import *  as  Realm from "realm-web";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { format } from "date-fns";
import trLocale from "date-fns/locale/tr"; // Türkçe dil dosyasını ekleyin

function Home() {
  const app = new Realm.App({ id: "application-0-onino" });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [vidIndex, setVidIndex] = useState(0);
  const [user, setUser] = useState();

  const videoRef = useRef(null);

  useEffect(() => {
    const login = async () => {
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user);
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("test").collection("videos");
      if (events.length === 0) {
      
        const allProducts = await collection.find({});
        setEvents(allProducts[0].videos);
      }

      var updatedEvents = [];

      for await (const change of collection.watch()) {
        if (
          change.operationType === "insert" ||
          change.operationType === "update" ||
          change.operationType === "replace"
        ) {
          updatedEvents.push(change.fullDocument);
        }
        console.log(updatedEvents)
        setEvents(updatedEvents[0].videos);
        updatedEvents =[]
      }
    };

    login();
  }, []);

  useEffect(() => {
    const handleVideoEnd = () => {
      setVidIndex((idx) => (idx + 1) % events.length);
    };
  
    const currentVideo = videoRef.current;

    if (currentVideo) {
      currentVideo.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (currentVideo) {
        currentVideo.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, [vidIndex, events]);

  useEffect(() => {
    // Belirli aralıklarla fonksiyonu çağır
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // 1000 milisaniye (1 saniye) aralıklarla güncelle

    // Komponent temizlendiğinde interval'i temizle
    return () => clearInterval(intervalId);
  }, []); // Boş bağımlılık dizisi, sadece bir kere çalışmasını sağlar

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const rates = await getExchangeRates();
        setExchangeRates(rates);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchExchangeRates();
  }, []);

  // Tarih ve saat bilgisini belirli bir formata göre formatla
  const formattedDate1 = format(currentDate, "dd.MM.yyyy ", {
    locale: trLocale,
  });

  // Tarih ve saat bilgisini belirli bir formata göre formatla
  const formattedDate2 = format(currentDate, "EEEE", {
    locale: trLocale,
  });
  // Tarih ve saat bilgisini belirli bir formata göre formatla
  const formattedDate3 = format(currentDate, "HH:mm:ss", {
    locale: trLocale,
  });

  const dolarCurency = exchangeRates && exchangeRates["TRY"];
  const euroCurency =
    exchangeRates && exchangeRates["TRY"] / exchangeRates["EUR"];
  const gbpCurency =
    exchangeRates && exchangeRates["TRY"] / exchangeRates["GBP"];
  const aznCurency =
    exchangeRates && exchangeRates["TRY"] / exchangeRates["AZN"];

  const currencies = [
    {
      name: "USD",
      class: "dolarbg",
      tlValue: dolarCurency ? dolarCurency.toFixed(2) : "",
    },
    {
      name: "EUR",
      class: "eurobg",
      tlValue: euroCurency ? euroCurency.toFixed(2) : "",
    },
    {
      name: "GBP",
      class: "gbpbg",
      tlValue: gbpCurency ? gbpCurency.toFixed(2) : "",
    },
    {
      name: "AZN",
      class: "aznbg",
      tlValue: aznCurency ? aznCurency.toFixed(2) : "",
    },
  ];

  const linkIptal = (e) => {
    e.preventDefault();
  };

  return (
    <div style={{ height: "100vh" }}>
      <div className="container-fluid home-layout">
        <div className="row">
          <div className="col-lg-3 left-sidebar">
            <div className="left-menu cart">
              <div>
                <h4>
                  Vatanını en çok sevenler,
                  <br />
                  Görevini en iyi yapanlardır.
                </h4>
                <img src="/images/ataturk.svg"></img>
              </div>
              <div></div>

              <div className="auto-date-div">
                <span className="auto-date-tarih">{formattedDate1}</span>
                <span className="auto-date-gun">{formattedDate2}</span>
                <span className="auto-date-saat">{formattedDate3}</span>
              </div>
              <div className="col-12">
                {loading ? (
                  <p>Veriler yükleniyor...</p>
                ) : (
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {currencies.map((currency, index) => (
                      <SwiperSlide>
                        <div
                          className={currency.class + " currency-div"}
                          key={index}
                        >
                          <h2 className="currency-name">{currency.name}</h2>
                          <p className="currency-tl-value">
                            {currency.tlValue + " ₺"}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
              <div className="screenoneone">
                <div className="hava-durumu-widget">
                  <a
                    id="ms-informer-link-058c25c2b49091649984549b06e6ba52"
                    className="ms-informer-link"
                    href="https://meteodays.com/tr/weather/overview/ankara"
                    onClick={linkIptal}
                  >
                    Ankara hava durumu
                  </a>
                </div>
                <div className="contact-div">
                  <h5>kulebblok.com</h5>
                  <h5>kockuleleribblok@hotmail.com</h5>
                  <h5>+90 542 504 3661</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 right-sidebar">
            <div className="header cart giris-slogan">
              <h1 className="hos-geldiniz">HOŞ GELDİNİZ</h1>
              <h3 className="yonetim-ad">KOÇ KULELERİ B BLOK YÖNETİMİ</h3>
            </div>
            <div
              className="video cart mt-3"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
             {!!user &&
              <video
        ref={videoRef}
        id="currentVideo"
        loop={events.length == 1 ? true : false}
        style={{ display: "block",
        objectFit: "cover",
        width: "100%",
        height: "100%",
      }}
        src={events.length > 0 ? events[vidIndex] : ""}
        key={events[vidIndex]}

        autoPlay
        controls
        muted
      />}
            </div>
            <div className="row">
              <div className="col-12 footer-box">
                <div className="cart footer">
                  <div>
                    {" "}
                    <button className="guncel-button">Güncel</button>
                  </div>
                  <MarqueGuncelHaberler />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;